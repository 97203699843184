import type { Route } from 'nextjs-routes';

const TEMPLATE_MAP: Record<Route['pathname'], string> = {
  '/': 'Explorer | Vanar Chain',
  '/txs': 'Transactions | Vanar Chain',
  '/txs/kettle/[hash]': 'Kettle %hash% transactions | Vanar Chain',
  '/tx/[hash]': 'Transaction %hash% | Vanar Chain',
  '/blocks': 'Blocks | Vanar Chain',
  '/block/[height_or_hash]': 'Block %height_or_hash% | Vanar Chain',
  '/accounts': 'Top accounts | Vanar Chain',
  '/address/[hash]': 'Address details for %hash% | Vanar Chain',
  '/verified-contracts': 'Verified contracts | Vanar Chain',
  '/contract-verification': 'Verify contract',
  '/address/[hash]/contract-verification': 'Contract verification for %hash% | Vanar Chain',
  '/tokens': 'Tokens | Vanar Chain',
  '/token/[hash]': '%symbol% token details | Vanar Chain',
  '/token/[hash]/instance/[id]': 'Token instance for %symbol% | Vanar Chain',
  '/apps': 'Apps marketplace | Vanar Chain',
  '/apps/[id]': '- %app_name% | Vanar Chain',
  '/stats': 'Statistics | Vanar Chain',
  '/api-docs': 'REST API | Vanar Chain',
  '/graphiql': 'GraphQL | Vanar Chain',
  '/search-results': 'Search result for %q%',
  '/auth/profile': '- My Profile | Vanar Chain',
  '/account/watchlist': '- Watchlist | Vanar Chain',
  '/account/api-key': '- API keys | Vanar Chain',
  '/account/custom-abi': '- Custom ABI | Vanar Chain',
  '/account/public-tags-request': '- Public tag requests | Vanar Chain',
  '/account/tag-address': '- Private tags | Vanar Chain',
  '/account/verified-addresses': '- My verified addresses | Vanar Chain',
  '/withdrawals': 'Withdrawals | Vanar Chain',
  '/visualize/sol2uml': 'Solidity UML diagram | Vanar Chain',
  '/csv-export': 'Export data to CSV | Vanar Chain',
  '/l2-deposits': 'Deposits (L1 > L2) | Vanar Chain',
  '/l2-output-roots': 'Output roots | Vanar Chain',
  '/l2-txn-batches': 'Tx batches (L2 blocks) | Vanar Chain',
  '/l2-withdrawals': 'Withdrawals (L2 > L1) | Vanar Chain',
  '/zkevm-l2-txn-batches': 'zkEvm L2 Tx batches | Vanar Chain',
  '/zkevm-l2-txn-batch/[number]': 'zkEvm L2 Tx batch %number% | Vanar Chain',
  '/404': 'Error - page not found | Vanar Chain',

  // service routes, added only to make typescript happy
  '/login': 'Login | Vanar Chain',
  '/api/media-type': 'Node API media type | Vanar Chain',
  '/api/proxy': 'Node API proxy | Vanar Chain',
  '/api/csrf': 'Node API CSRF token | Vanar Chain',
  '/api/healthz': 'Node API health check | Vanar Chain',
  '/auth/auth0': 'Authentication | Vanar Chain',
  '/auth/unverified-email': 'Unverified email | Vanar Chain',
};

export function make(pathname: Route['pathname']) {
  const template = TEMPLATE_MAP[pathname];

  return `%network_name% ${ template }`;
}
